// ================================
// FORMS ==========================
// ================================





//========================================================
// INPUT CUSTOMIZATION
//========================================================
.jcf-select
	margin-bottom: 0
	border: none
	min-width: auto
	height: auto
	background-color: transparent

	.jcf-select-opener
		background-color: transparent
		background-image: url("../images/icons/arrow_down_icon--white.png")
		background-position: left 30%

	.jcf-select-text	
		+font-mixin( $f1, 1rem, 400, normal, normal, uppercase , #fff)

	option

	//MEDIA QUERY
	+mq('tablet', max)
		color: #000
			
		.jcf-select-text	
			+font-mixin( $f1, 1.875rem, 700, normal, normal, none , #0E284A)

		.jcf-select-opener
			background-image: url("../images/icons/arrow_down_icon--black.png")
			background-position: left 70%

.jcf-select-drop
	.jcf-select-drop-content
		border: 1px solid #0E284A
		.jcf-list-content
			li
				display: block

				.jcf-option

					&.jcf-selected

.jcf-focus *
	border-color: #0E284A!important