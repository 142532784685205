// ------------------------------------------------------
//SCALE MIXIN--------------------------------------------
//used for scaling svg sprites to needed size
//$inputwidth - natural width of an element according to spritemap
//$outputwidth - demanded width of element on output
=scale-ratio-mixin( $inputWidth, $outputWidth )
	$scaleRatio : $outputWidth / $inputWidth
	font-size: $scaleRatio

@function	get-scale-ratio( $inputWidth, $outputWidth )
	$scaleRatio : $outputWidth / $inputWidth
	@return $scaleRatio

// ------------------------------------------------------
//GET PROPERTY FROM SVG MAP MIXIN------------------------
@function get-property( $spriteMap, $iconName, $getProperty: width )
	$sprite: map-get($spriteMap, $iconName)
	$propertyValue: map-get($sprite, $getProperty)	
	@return strip-unit($propertyValue)

// ------------------------------------------------------
/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number)
	@if type-of($number) == 'number' and not unitless($number)
		@return $number / ($number * 0 + 1)

	@return $number
