$helvetica: Roboto, Helvetica, Arial, sans-serif

//colors
$font-color0: #fff
$font-color1: #000000

$brand-primary: #E10121
$brand-secondary: #41535A


//fonts
$f1: "Lato", $helvetica
$f2: "GothemNarrowBook", $helvetica
$f3: "AvenirBook", $helvetica




