// https://www.liquidlight.co.uk/blog/article/creating-svg-sprites-using-gulp-and-sass/
// http://www.sassmeister.com/gist/d0f7c4a14b965d6fcb17

// $ieSprite: '.lt-ie9' !default;
$ieSprite: '.ie' !default;
$baseFontSize: 14px !default;

// Converts px to em
@function mq-px2em($px, $base-font-size: $baseFontSize) {
	@if (unitless($px)) {
		@warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
		@return mq-px2em($px + 0px); // That may fail.
	} @else if (unit($px) == em) {
		@return $px;
	}
	@return ($px / $base-font-size) * 1em;
}

// Gets an attribute from the sass map
// Added: spriteMap option
@function sprite-attr($spriteMap, $icon, $attr) {
	$icon: map-get($spriteMap, $icon);
	@return map-get($icon, $attr);
}

// Added: spriteMap option
@function icon-attr($spriteMap, $icon) {
	$attr: (
		width: sprite-attr($spriteMap, $icon, width),
		height: sprite-attr($spriteMap, $icon, height),
		x: sprite-attr($spriteMap, $icon, backgroundX),
		y: sprite-attr($spriteMap, $icon, backgroundY)
	);

	@return $attr;
}

// IE Sprite Mixin for when rotation is used
// Added: spriteMap option
@mixin ie-svg-sprite($spriteMap, $icon, $type: all) {
	
	$sprite: map-get($spriteMap, sprite);
	$iconMap: icon-attr($spriteMap, $icon);
	
	@if $ieSprite {
		#{$ieSprite} & {
			@if $type == all {
				// Shares the PNG background
				background-image: url(map-get($sprite, pngPath));
			}
			// Outputs dimensions of icon
			@if $type == all or $type == size {
				width: map-get($iconMap, width) + 1 ;
				height: map-get($iconMap, height) + 1;
			}
			// Outputs background position
			@if $type == all or $type == bg {
				background-position: (map-get($iconMap, x) - 5)  (map-get($iconMap, y) - 5);
			}
		}
	}
}

// For use with the gulp sprite plugin
// Added: spriteMap option
@mixin svg-sprite($spriteMap, $icon, $type: all) {
	$sprite: map-get($spriteMap, sprite);
	
	@if $type == all {
		// Shares the backgrounds
		display: inline-block;
		background-image: url(map-get($sprite, svgPath));

		//in em
		background-size: mq-px2em(map-get($sprite, width)) mq-px2em(map-get($sprite, height)); 

		// in px
		// background-size: map-get($sprite, width) map-get($sprite, height);
	}

	$iconMap: icon-attr($spriteMap, $icon);

	// Outputs dimensions in em
	@if $type == all or $type == size {
		//in em
		width: mq-px2em(map-get($iconMap, width) + 1);
		height: mq-px2em(map-get($iconMap, height) + 1);

		// in px
		// width: map-get($iconMap, width) + 1;
		// height: map-get($iconMap, height) + 1;
	}

	// Outputs background position in em
	@if $type == all or $type == bg {
		//in em
		background-position: mq-px2em(map-get($iconMap, x)-5) mq-px2em(map-get($iconMap, y)-5);

		// in px
		// background-position: (map-get($iconMap, x)-5) (map-get($iconMap, y)-5) ; //exclude 5 for padding set in gulfile.js
	}

	// Add ie fallback
	@include ie-svg-sprite($spriteMap, $icon, $type);

}



