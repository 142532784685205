// ROOT FONT SIZE
html
	font-size: 16px

	//MEDIA QUERY
	+mq('md-end', max)
		font-size: 14px
	+mq('phone-wide', max)
		font-size: 12px


.color
	&--dark
		color: #0e274b!important
	&--dark-blue
		color: #0060ff!important
	&--red
		color: #F26F8B!important
	&--green
		color: #B0CD9C!important
	&--yellow
		color: #F3CB77!important


//==============================================
// FONT STYLES =================================
//==============================================
//     1            2          3           4           5             6             7
// font-family, font-size, font-weight, font-style, line-height, text-transform, color

// p =====================================================
p
	line-height: 1.375rem
	&.p
		+font-mixin( $f2, 0.875rem, 400, normal, 1.375rem, none , #374b69)
		
		&--italic
			+font-mixin( $f1, 1.375rem, 400, italic, 1.750rem, none , #0e274b)
	
		&--uppercase
			text-transform: uppercase
		
		&-animate
			+font-mixin( $f1, 1.500rem, 300, normal, 1.750rem, none , #0e274b)
			//MEDIA QUERY
			+mq('phone-wide', max)
				font-size: 14px

// ul =====================================================
ul
	

// a =====================================================
a
	&.button-link
		+font-mixin( $f1, 0.875rem, 700, normal, normal, none , #fff)
		text-decoration: underline


// span ==================================================
span
	&.logo
		+font-mixin( $f3, 1.438rem, 900, normal, normal, none , #323747)


// =================================================
strong


// H1 =====================================================

h1,h2
	+font-mixin( $f1, 3.750rem, 900, normal, normal, none , #fff)	

	// variations
	&.h

		&--dark-blue
			color: #0E284A
		
		&--underlined
			display: inline-block		
			+text-underline-mixin( #0e274b, 0.15, 6px) //$color|$opacity|$height
			&:after
				bottom: 7px

		&--extra-big
			+font-mixin( $f1, 2.500rem, 300, italic, normal, none , #0e274b)	
	
	//MEDIA QUERY
	+mq('phone-wide', max)
		font-size: 2.5rem
		
		&.h
			&--underlined
				&:after
					bottom: 0px
		
// H3 =====================================================
h3
	+font-mixin( $f1, 1.375rem, 300, normal, normal, none , #fff)

	strong
		+font-mixin( $f1, 1.375rem, 900, normal, normal, none , #fff)

	// variations
	&.h
		&--number
			+font-mixin( $f1, 6.250rem, 700, normal, 5rem, none , #0e274b)
			opacity: 0.22
		&--quotes
			+font-mixin( $f1, 6.500rem, 900, normal, 5rem, none , #cad0d7)

// H4 =====================================================
h4
	+font-mixin( $f1, 1.625rem, 700, normal, normal, none , #0e274b)
	
	&.h-animate
		+font-mixin( $f1, 1.625rem, 700, normal, normal, none , #1a3153)

// H5 =====================================================
h5
	+font-mixin( $f1, 1.875rem, 800, normal, normal, uppercase , #0e274b)


//button==================================================
button, a.button
	+font-mixin( $f1, 1rem, 400, normal, normal, uppercase , #fff)

//==============================================
//RESPONSIVE FONT
//==============================================
.responsive-font
	// Viewport-based size - Minimum font size - Maximum font size - Fallback for viewport
	+responsive-font(10vw, 55px, 120px, 50px)

	//MEDIA QUERY
	+mq('tablet-wide', min) //1024px
		+responsive-font(12vw, 55px, 120px, 50px)
	+mq('desktop-wide', min) //1440px
		+responsive-font(14vw, 55px, 120px, 50px)
		
