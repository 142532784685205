//==========================================
// MODIFIERS ===============================
//==========================================

//========================
// FLEX
//========================

.vt-start
	justify-content: flex-start!important

.vt-around
	justify-content: space-around!important
	//MEDIA QUERY	
	+mq('md-end', max)
		justify-content: center!important

.vt-between
	justify-content: space-between!important

.vt-center
	justify-content: center!important

.vt-end
	justify-content: flex-end!important

.hr-start
	align-items: flex-start!important

.hr-center
	align-items: center!important

.hr-end
	align-items: flex-end!important

//========================
// TEXT ALIGN
//========================
.taj
	text-align: justify!important
.tal
	text-align: left!important
.tac
	text-align: center!important
.tar
	text-align: right!important

//========================
// VERTICAL ALIGN
//========================
.vat
	vertical-align: top
.vam
	vertical-align: middle
.vab
	vertical-align: bottom	


//========================
//LAYOUT
//========================
.display-table
	display: table
	height: 100%
	width: 100%

	//width
	&--w33p
		width: 33%
	&--w20p
		width: 20%
	&--w40p
		width: 40%
	&--w50p
		width: 50%

.table-cell
	display: table-cell	

	//position
	&--vat
		vertical-align: top
	&--vam
		vertical-align: middle
	&--vab
		vertical-align: bottom
	&--tal
		text-align: left
	&--tac
		text-align: center
	&--tar
		text-align: right	
	
	//width
	&--w33p
		width: 33%
	&--w20p
		width: 20%
	&--w40p
		width: 40%
	&--w50p
		width: 50%

//============================
// POSITIONING
//============================
.pos-rel
	position: relative

.pos-abs
	position: absolute

//============================
// Z-INDEXES
//============================	
.zi-1
	z-index: -1
.zi0
	z-index: 0
.zi1
	z-index: 1
.zi2
	z-index: 2
.zi3
	z-index: 3

//============================
// MARGINS
//============================

.mgt30vh	
	margin-top: 20vh

// margin top
.mgt
	&-0
		margin-top: 0
	&-5
		margin-top: 5px
	&-10
		margin-top: 10px
	&-15
		margin-top: 15px
	&-20
		margin-top: 20px
	&-25
		margin-top: 25px
	&-30
		margin-top: 30px
	&-35
		margin-top: 35px
	&-40
		margin-top: 40px
	&-45
		margin-top: 45px
	&-50
		margin-top: 50px
	&-55
		margin-top: 55px
	&-60
		margin-top: 60px
	&-65
		margin-top: 65px
	&-70
		margin-top: 70px

// margin bottom
.mgb
	&-0
		margin-bottom: 0
	&-5
		margin-bottom: 5px
	&-10
		margin-bottom: 10px
	&-15
		margin-bottom: 15px
	&-20
		margin-bottom: 20px
	&-25
		margin-bottom: 25px
	&-30
		margin-bottom: 30px
	&-35
		margin-bottom: 35px
	&-40
		margin-bottom: 40px
	&-45
		margin-bottom: 45px
	&-50
		margin-bottom: 50px
	&-55
		margin-bottom: 55px
	&-60
		margin-bottom: 60px
	&-65
		margin-bottom: 65px
	&-70
		margin-bottom: 70px
	&-75
		margin-bottom: 75px
	&-80
		margin-bottom: 80px
	&-85
		margin-bottom: 85px
	&-90
		margin-bottom: 90px				
	&-95
		margin-bottom: 95px
	&-100
		margin-bottom: 100px	
	
//============================
// MARGINS RESPONSIVE
//============================

.mgt
	
	//ems
	&-0_5em
		margin-top: 0.5em
	&-1em
		margin-top: 1em
	&-1_5em
		margin-top: 1.5em
	&-2em
		margin-top: 2em

	//rems
	&-0_5rem
		margin-top: 0.5rem
	&-1rem
		margin-top: 1rem
	&-1_5rem
		margin-top: 1.5rem
	&-2rem
		margin-top: 2rem
	&-3rem
		margin-top: 3rem
	&-4rem
		margin-top: 4rem
	&-5rem
		margin-top: 5em
	&-6rem
		margin-top: 6rem
	&-7rem
		margin-top: 7rem
	&-8rem
		margin-top: 8rem
	&-9rem
		margin-top: 9rem
	&-10rem
		margin-top: 10rem

.mgb
	// ems
	&-0_5em
		margin-bottom: 0.5em
	&-1em
		margin-bottom: 1em
	&-1_5em
		margin-bottom: 1.5em
	&-2em
		margin-bottom: 2em
	&-2_5em
		margin-bottom: 2.5em
	&-3em	
		margin-bottom: 3em
	&-3_5em
		margin-bottom: 3.5em
	&-4em	
		margin-bottom: 4em
	&-4_5em
		margin-bottom: 4.5em
	&-5em	
		margin-bottom: 5em
	&-5_5em
		margin-bottom: 5.5em
	&-6em	
		margin-bottom: 6em
	&-6_5em
		margin-bottom: 6.5em			

	//rems
	&-0_5rem
		margin-bottom: 0.5rem
	&-1rem
		margin-bottom: 1rem
	&-1_5rem
		margin-bottom: 1.5rem
	&-2rem
		margin-bottom: 2rem
	&-2_5rem
		margin-bottom: 2.5rem
	&-3rem	
		margin-bottom: 3rem
	&-3_5rem
		margin-bottom: 3.5rem
	&-4rem	
		margin-bottom: 4rem
	&-4_5rem
		margin-bottom: 4.5rem
	&-5rem	
		margin-bottom: 5rem
	&-5_5rem
		margin-bottom: 5.5rem
	&-6rem	
		margin-bottom: 6rem
	&-6_5rem
		margin-bottom: 6.5rem
	
	//responsive margin reset
	&-md
		//MEDIA QUERY
		+mq('md-end', max)
			margin: 0


//============================
//PADDINGS
//============================
.pd-l-r-0
	padding-left: 15px
	padding-right: 15px

.pd-l-r-1em
	padding-left: 1em
	padding-right: 1em

.pd-l-r-2em
	padding-left: 2em
	padding-right: 2em	

.pdr-05em
	padding-right: 0.5em

.pdr-1em
	padding-right: 1em

.pd20
	padding: 20px
.pd40
	padding: 40px

.pdt20	
	padding-top: 20px
.pdt100
	padding-top: 100px
.pdt150
	padding-top: 150px

.pdl15
	padding-left: 15px
.pdl35
	padding-left: 35px	

.pd-100-50
	padding: 100px 50px

//============================
// FONT SIZES
//============================
.fz10
	font-size: 10px
.fz25
	font-size: 25px
.fz30
	font-size: 30px
.fz35
	font-size: 35px
.fz40
	font-size: 40px
.fz45
	font-size: 45px
.fz50
	font-size: 50px
.fz55
	font-size: 55px	
.fz60
	font-size: 60px
.fz65
	font-size: 65px
.fz70
	font-size: 70px
.fz75
	font-size: 75px

//============================
// CLEARFIX
//============================

.group:after 
  content: ""
  display: table
  clear: both

// HEIGHT
.h100p 
	height: 100%

.no-padding
	padding: 0!important
