// FALLBACK FONTS
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300')
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,800,900')

//WEBSITE FONTS
// @import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700')
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800')
// @import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700')

+font-face("GothemNarrowBook", "../fonts/GothamNarrowBook/GothamNarrowBook", $file-formats: eot woff ttf)
+font-face("AvenirBook", "../fonts/AvenirBook/AvenirBook", $file-formats: eot woff ttf)
