// Make sure your sprite does not exceed dimensions of 2300px x 2300px - otherwise <= iOS7 won't display the image at all.

// https://www.liquidlight.co.uk/blog/article/creating-svg-sprites-using-gulp-and-sass/
//SPRITE MAPS INIT
@import "_svg_icons__spritemap1.scss"
// @import "_svg_icons__spritemap2.scss"

//SPRITE MIXINS INIT
@import "../mixins/sprites/sprite_mixins.scss"

//CUSTOM SPRITE MIXINS INIT
@import "../mixins/sprites/sprite_mixins.sass"

//============================================
// ICONS
//============================================

//--------------------------------------------
.sprite
	// parameters
	$icon: circle
	$iconWidth: 293

	+svg-sprite( $iconsSet1, $icon )
	//scale to demanded size by width for SVG sprite @scale-mixin( $inputWidth, $outputWidth )
	font-size: get-scale-ratio( get-property( $iconsSet1, $icon, width) + 1, $iconWidth) + em

.ie .sprite
	// parameters
	$icon: circle
	$iconWidth: 293

	//set font-size to normal
	font-size: 1em

	//scale to demanded size by width for IE PNG sprite
	transform: scale( get-scale-ratio( get-property( $iconsSet1, $icon, width) + 1, $iconWidth) )
	transform-origin: left top