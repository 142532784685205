//====================================
// COMPONENTS ========================
//====================================

//PARTNERS LIST
ul.list
	display: -webkit-flex
	display: -moz-flex
	display: -ms-flex
	display: -o-flex
	display: flex
	justify-content: space-around
	flex-wrap: wrap
	align-items: center
	li
		//MEDIA QUERY
		+mq('phone-wide', max)
			flex-basis: 100%

	&--partners
		padding: 60px 0		
		
		.list-text
			width: 200px
			text-align: justify
			p
				color: #BDC4CE
				font-family: $f2
				font-size: 18px
				font-weight: 300			

			+mq('phone-wide', max)
				text-align: center
				padding: 20px

		img
			padding: 20px
	
	&--goals
		li
			flex-basis: 20%
			
			//MEDIA QUERY
			+mq('md-end', max)
				flex-basis: 50%
				margin-bottom: 3rem
			+mq('phone-wide', max)
				flex-basis: 100%
	
	&--list-footer
		justify-content: flex-start
		li
			margin-right: 40px

		//MEDIA QUERY
		+mq('phone-wide', max)
			justify-content: space-around
			li
				flex-basis: auto
				margin-right: 0

				&:first-of-type
					flex-basis: 100%
					margin-bottom: 30px
					text-align: center


// BENEFIT ITEM
.benefit-item
	
	// -review
	.benefit-item_review
		display: table
		overflow: hidden
		padding: 20px 60px

		.left			
			display: table-cell
			padding-right: 10px
			h3
				display: inline-block
		.right
			display: table-cell
			padding: 20px 20px
			p
		
		//MEDIA QUERY
		+mq('phone-wide', max)
			padding: 10px 10px

	// -comment
	.benefit-item_comment
		display: table
		overflow: hidden
		padding: 20px 60px		

		.left
			position: relative
			display: table-cell
			
			img.avatar
				width: 85px
				height: 85px			

		.right
			display: table-cell
			padding: 20px 20px
			p
			.comment_icon
				width: 100%
		
		//MEDIA QUERY
		+mq('phone-wide', max)
			padding: 10px 10px

// OFFERINGS
.offerings
	display: -webkit-flex
	display: -moz-flex
	display: -ms-flex
	display: -o-flex
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	
	.offerings_box
		padding: 62px 62px 70px
		background-color: #E5EEF3
		flex-basis: 32%
		align-self: center
		display: -webkit-flex
		display: -moz-flex
		display: -ms-flex
		display: -o-flex
		display: flex
		flex-direction: column
		justify-content: center
		
		h4
			padding-bottom: 1.250rem
		p
			text-align: justify
		
		//MEDIA QUERY
		+mq('md-end', max)
			flex-basis: 48%
			margin: 0 0 2rem 0
			padding: 30px 35px
		+mq('phone-wide', max)
			flex-basis: 100%

// QUOTES WRAPPER
.quote-wrapper


// PHONE
.phone
	position: absolute
	bottom: 0
	right: 10%
	//MEDIA QUERY
	+mq('phone-wide', max)
		display: none

// LOGO
.img-logo
	margin-right: 15px

// ====================================================
// ANIMATION BLOCKS
// ====================================================

//layout
.block_container
	display: table
	// height: 585px
	height: 50vh
	width: 100%
	table-layout: fixed
	margin: 0 auto
	position: relative

	.block_container__content
		display: table-cell
		vertical-align: middle
		text-align: left
		
		&.active
			.text-wrapper
				&:before
					opacity: 1				
	
	//MEDIA QUERY
	+mq('tablet', max)
		// padding: 120px 0
	+mq('phone-wide', max)
		// height: 250px
		// padding: 40px 0
		// padding: 75px 0	


	// -elements
	.text-wrapper
		display: flex		
		flex-direction: column
		align-content: center
		justify-content: center

		padding-left: 100px
		position: relative
		text-align: left
		min-height: 300px

		&:before
			transition: 1s opacity
			+absolute-el-mixin( 10px , 100%)
			left: 0
			bottom: 0
			background-color: #1898D1
			opacity: 0
		
		//MEDIA QUERY
		+mq('tablet', max)
			padding-left: 50px
		+mq('phone-wide', max)
			padding-left: 25px

.scrollmagic-pin-spacer
	top: 0!important

.spacer
	// min-height: 1px
	// border-bottom: 1px solid red

.fullscreen-image-wrapper
	position: absolute
	height: 50vh
	width: 100vw	
	z-index: 1
	
	.relative-wrapper
		height: 100%
		width: 50%
		display: inline-block
		position: relative

// animation
.image-animated
	position: absolute
	right: 0%
	top: 50%
	transform: translate( -0% , -50%)
	max-width: 100%
	height: auto
	
	//MEDIA QUERY
	+mq('phone-wide', max)		

	&.animate1

	&.animate2

	&.animate3
		transform: translate( -50% , -50%)
		
		//MEDIA QUERY
		+mq('phone-wide', max)



//absolute heading
.h-abs
	position: absolute!important
	top: 0px
	left: 50%
	transform: translate( -50% , 0%)

	//MEDIA QUERY
	+mq('phone-wide', max)
		top: 30px

//fixed heading
.targetEl_Heading
	&.fixed
		position: fixed
		top: 105px
		left: 50%
		transform: translate( -50% , 0%)

#content
	position: relative
	p
		font-size: 25px
		line-height: 45px
	img
		display: block			
		margin: 0 0 0 auto
		margin: 50px 0 0 auto


//====================================
// ICONS
//====================================

.quotes_icon
	position: absolute
	width: 36px
	height: 36px
	left: -20px
	top: -10px
	
	//MEDIA QUERY
	+mq('phone-wide', max)
		left: -5px
	

.quotes_icon--big
	content: ''
	position: absolute
	top: 0
	left: 0
	width: 125px
	height: 125px
	background-image: url("../images/icons/comment_icon.png")
	background-size: cover
	background-repeat: no-repeat

.h--quotes
	position: absolute
	top: 0
	left: 0


.comment_icon--big
	width: 640px

	//MEDIA QUERY
	+mq('tablet-small', max)
		width: 100%

//====================================
// TYPED JS
//====================================
.typed-cursor
	opacity: 1
	animation: blink 0.7s infinite

+keyframes(blink)

	0%
		opacity: 1
	50%
		opacity: 0
	100%
		opacity: 1



