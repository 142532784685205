//-----------------------------------------
//PRELOADER
//-----------------------------------------
.preloader-wrapper
	background: none repeat scroll 0 0 #ffffff	
	height: 100%
	width: 100%
	position: fixed
	bottom: 0
	left: 0	
	right: 0
	top: 0	
	z-index: 9999
	display: table

.preloader
	display: table-cell
	vertical-align: middle
	text-align: center	

// LOADER 1
.spinner 
	margin: 100px auto
	width: 50px
	height: 50px
	position: relative
	text-align: center
	animation: sk-rotate 2.0s infinite linear


.dot1, .dot2, .dot3
	width: 60%
	height: 60%
	display: inline-block
	position: absolute
	top: 0
	background-color: #ed1a26
	border-radius: 100%
	animation: sk-bounce 2.0s infinite ease-in-out

.dot2
	top: auto
	bottom: 0
	animation-delay: -1.0s
	background-color: #787878

.dot3
	top: -10px
	/*bottom: 0;*/
	left: 35px
	animation-delay: -0.2s
	background-color: #C8C8C8

@keyframes sk-rotate
	100%
		transform: rotate(360deg)


@keyframes sk-bounce
	0%, 100%
	  transform: scale(0.0)
	50%
	  transform: scale(1.0)