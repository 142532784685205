//====================================
// BUTTONS ===========================
//====================================

.button-wrapper
	display: block
	text-align: center

button, a.button
	transition: 1s all

.button
	padding: 21px 37px
	color: #fff	
	img
		float: left
		margin-right: 10px


	// shape
	&--rounded
		border-radius: 26px
	
	//color
	&--light-blue-border
		background-color: #fff
		border: 1px solid #409FD1
		text-decoration: underline
		color: #409FD1

	&--light-blue
		background-color: #409FD1
		border: 1px solid #409FD1
		text-decoration: underline

		&:hover
			background-color: darken( #409FD1, 10% )
			border-color: darken( #409FD1, 10% )
	
	&--dark-blue
		background-color: #0067FA
		border: 1px solid #0067FA
		&:hover
			background-color: darken( #0067FA, 10% )
			border-color: darken( #0067FA, 10% )

a.button-link
	display: inline-block
	&:hover
		text-decoration: none


//----------------------------
// BUTTON TO TOP
//----------------------------
.button_top
	position: fixed
	bottom: 20px
	right: 10px
	height: 71px
	width: 71px
	background-color: #0060ff
	opacity: 0.42
	border-radius: 50%
	text-align: center
	z-index: 9999	
	transition: 1s box-shadow, 1s background-color
	display: table	
	display: none//initial state


	&:hover
		cursor: pointer
		box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.45)
		background-color: darken( #0060ff, 20% )
		i
			// font-size: 33px
	&:focus
	
	a
		vertical-align: middle
		text-align: center
		display: table-cell


span.divider
	margin-right: 34px

//---------------------------------------------------
//ICON HOVER EFFECTS---------------------------------
//---------------------------------------------------
.icon-hover-effect
	li
		a
			&:hover
				border-color: $brand-primary
				i				
					animation: toRightFromLeft .5s forwards
					color: $brand-primary
	
	@keyframes toRightFromLeft
		49%
			transform: translate(100%)		
		50%
			opacity: 0
			transform: translate(-100%)		
		51%
			opacity: 1