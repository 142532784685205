//====================================
// BODY
//====================================
body

//====================================
// HEADER
//====================================
header
	background-image: url("../images/header/bg.png")
	background-position: center center
	background-size: cover


//====================================
// MAIN
//====================================
main
	height: 100vh

//====================================
// SECTIONS
//====================================
section

.section
	text-align: center
	
	&--header
		.section_container
			height: calc( 100vh - 100px)
	
	&--partners
		background-color: #F3F4F6

	&--goals
		padding-top: 90px
		padding-bottom: 90px

		//MEDIA QUERY
		+mq('md-end', max)
			padding: 90px 0

	&--benefits
		padding: 90px 0px
		background-color: #EEF3F6
		margin-bottom: 90px
		
		//MEDIA QUERY
		+mq('md-end', max)
			padding: 45px 0		

	&--animated
		padding: 150px 0
		position: relative
		overflow: hidden
		text-align: right

		// -webkit-transform: translate3d(0,0,0) //chrome perfomance fix
		background-image: url("../images/section--animated/bg.png")
		background-attachment: fixed
		background-size: 100% auto
		background-repeat: no-repeat
		
		.animation-wrapper
			position: relative
		//MEDIA QUERY
		+mq('phone-wide', max)
			// padding-bottom: 40px

	&--offering
		padding: 105px 0 105px
		background-image: url("../images/section--offering/bg.png")
		background-size: 100% auto
		background-repeat: no-repeat

		//MEDIA QUERY
		+mq('md-end', max)
			padding: 45px 0	
			h1,h2,h3
				margin-bottom: 5rem

	&--quote
		padding: 90px
		//MEDIA QUERY
		+mq('md-end', max)
			padding: 45px 20px


.section_container
	display: table
	table-layout: fixed
	width: 100%

.section_container__content
	display: table-cell
	vertical-align: middle
	text-align: center

//====================================
// FOOTER
//====================================
footer
	
	background-image: url("../images/footer/bg.png")	
	background-size: cover
	background-position: left bottom
	background-repeat: no-repeat
	
	.footer-container
		position: relative
		height: 410px
		display: table
		table-layout: fixed
		width: 100%		

		.footer-container_content
			display: table-cell
			vertical-align: bottom
			padding-bottom: 25px
			
			//MEDIA QUERY
			+mq('phone-wide', max)
				vertical-align: middle


// ==================================================
// OVERLAYS
// ==================================================
.overlay-dark
	+overlay-mixin( $overlayColor: rgba(0,0,0,.5), $width: 100%,$height: 100%, $z: -1)
