//source
// http://engageinteractive.co.uk/blog/top-10-scss-mixins

//load mixin maps
@import "mixins_maps.scss"

//==============================================
// MIXINS=======================================
//==============================================

// ---------------------------------------------
//Z-INDEX MIXIN---------------------------------
=z-index-mixin( $zindex: 1 )
	position: relative
	z-index: $zindex

// ---------------------------------------------
//BG MIXIN--------------------------------------
=bg-mixin( $url, $bg-repeat: no-repeat , $bg-pos: center center, $bg-size: cover, $bg-color: transparent )
	background-image: $url
	background-repeat: $bg-repeat
	background-size: $bg-size
	background-position: $bg-pos
	position: relative
	background-color: $bg-color

// ---------------------------------------------
//OVERLAY MIXIN
=overlay-mixin( $overlayColor: rgba(0,0,0,.5), $width: 100%, $height: 100%, $top: auto, $left: 0, $bottom: 0, $right: 0, $z: 0,$trans: 1s all)
	position: relative
	&:before
		transition: $trans
		+absolute-el-mixin( $width , $height)
		left: $left
		right: $right
		top: $top
		bottom: $bottom
		background-color: $overlayColor
		z-index: $z

// ---------------------------------------------
//TRANSFORM TRANSLATE MIXIN---------------------
=translate( $x: 0, $y: 0)
	transform: translate( $x, $y)

// -----------------------------------------------
//PESEUDO ELEMENT MIXIN--------------------------
=absolute-el-mixin( $width: false, $height: false)
	position: absolute
	content: ''
	
	@if $width
		width: $width
	@if $height	
		height: $height

// ------------------------------------
//HOVER MIXIN--------------------------
=hover-mixin( $bg-color: false, $text-color: false)
	transition: 1s all
	&:hover		
		@if $bg-color
			background-color: $bg-color
		@if $text-color
			color: $text-color
		span
			@if $text-color
				color: $text-color

// ------------------------------------------------
//TEXT UNDERLINE MIXIN----------------------------------
=text-underline-mixin ( $color: red , $opacity: 1, $height: 2px)
	position: relative
	&:after
		+absolute-el-mixin( 100%, $height)
		opacity: $opacity	
		background-color: $color
		left: 0
		bottom: 0

// ---------------------------------------------------
//FONT MIXIN------------------------------------------
=font-mixin( $ff , $size: false, $weight: false,  $fontstyle: normal, $lh: normal, $textTransform: false, $color: false )
	font-family: $ff
	@if $size
		font-size: $size	
	@if $weight
		font-weight: $weight
	@if $fontstyle	
		font-style: $fontstyle
	@if $lh
		line-height: $lh
	@if $textTransform
		text-transform: $textTransform
	@if $color
		color: $color

// ---------------------------------------------------
//FONT FACE ------------------------------------------
// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// mixin with a fallback to pixels when rem isn’t supported
// +font-size(14px)

@function calculateRem($size)
	$remSize: $size / 16px
	@return $remSize * 1rem

=font-size($size)
  font-size: $size
  font-size: calculateRem($size)

// -----------------------------------------------------
//MEDIA QUERY MIXIN-------------------------------------
// +mq('tablet-wide') { attribute: value }
=mq($width, $type: min)
	@if map_has_key($breakpoints, $width)
		$width: map_get($breakpoints, $width)
		@if $type == max
			$width: $width - 1px
		@media only screen and (#{$type}-width: $width)
			@content

// ------------------------------------------------------
//TRIANGLE MIXIN-----------------------------------------
// $color, $direction, $size: 6px, $position: absolute, $round: false	
=css-triangle-mixin($color, $direction, $size: 6px, $round: false)
	+absolute-el-mixin()
	width: 0
	height: 0
	@if $round
		border-radius: 3px

	@if $direction == down
		border-left: $size solid transparent
		border-right: $size solid transparent
		border-top: $size solid $color
		margin-top: 0 - round( $size / 2.5 )
	@else if $direction == up
		border-left: $size solid transparent
		border-right: $size solid transparent
		border-bottom: $size solid $color
		margin-bottom: 0 - round( $size / 2.5 )
	@else if $direction == right
		border-top: $size solid transparent
		border-bottom: $size solid transparent
		border-left: $size solid $color
		margin-right: -$size
	@else if  $direction == left
		border-top: $size solid transparent
		border-bottom: $size solid transparent
		border-right: $size solid $color
		margin-left: -$size

// ------------------------------------------------------    
//Z-INEDEX MIXIN-----------------------------------------
@function z($name)
	@if index($z-indexes, $name)
		@return (length($z-indexes) - index($z-indexes, $name)) + 1
	@else 
		@warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}'
		@return null

// ------------------------------------------------------
//TRUNCATE MIXIN-----------------------------------------
=truncate( $truncation-boundary )
	max-width: $truncation-boundary
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

// ------------------------------------------------------
//DIAGONAL SHAPE-----------------------------------------
// @param $color: The color of the shape, default #d71f55

// @param $direction:
// bl-to-tr for bottom-left to top right
// tr-to-bl for top-right to bottom left
// tl-to-br for top-left to bottom right
// br-to-tl for bottom-right to top left

// @param $height: The height of the shape, default 100px
// @param $width: The width of the shape, default 100vw
// @param $color: The color of the shape, default #d71f55

// @see also: http://stackoverflow.com/a/11074735/1465919

=diagonal-shape-mixin($color: #d71f55, $direction: 'bl-to-tr', $width: 100px, $height: 100px )
	border-style: solid

	@if $direction == 'bl-to-tr'
		border-width: 0 0 $height $width
		border-color: transparent $color $color transparent
	@else if $direction == 'tr-to-bl'
		border-width: $height $width 0 0
		border-color: $color transparent transparent $color
	@else if $direction == 'tl-to-br'
		border-width: 0 $width $height 0
		border-color: transparent  transparent $color $color
	@else if $direction == 'br-to-tl'
		border-width: $height 0 0 $width
		border-color: $color $color transparent  transparent


///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback), 
///                 minumum of 35px and maximum of 150px
/// @include responsive-font(5vw, 35px, 150px, 50px);

/// https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/
=responsive-font($responsive, $min, $max: false, $fallback: false)
	$responsive-unitless: $responsive / ($responsive - $responsive + 1)
	$dimension: if(unit($responsive) == 'vh', 'height', 'width')
	$min-breakpoint: $min / $responsive-unitless * 100
  
	@media (max-#{$dimension}: #{$min-breakpoint})
		font-size: $min
  
  
	@if $max
		$max-breakpoint: $max / $responsive-unitless * 100
    
		@media (min-#{$dimension}: #{$max-breakpoint})
			font-size: $max
    
  
  
	@if $fallback
		font-size: $fallback
  
  
	font-size: $responsive
