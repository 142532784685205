// =============================
// BURGER BUTTON 
// =============================
.toggle-button
	display: inline-block
	position: relative
	z-index: 9999
	cursor: pointer
	height: 47px
	width: 47px
	float: right
	background-color: transparent
	transition: transform .5s, background-color .5s

	span
		position: absolute
		background-color: #fff
		left: 50%
		transform: translate(-50%, 0)
		display: block
		height: 2px
		width: 28px
		transition: all 0.5s ease

	span:nth-child(1)
		top: 15px

	span:nth-child(2)
		top: 25px

	span:nth-child(3)
		top: 35px

	.transparent
		opacity: 0

	.rotate-top
		transform: rotatez(45deg)
		transform-origin: 0%
		left: 17px
		background-color: #0E284A

	.rotate-bottom
		transform: rotatez(-45deg)
		transform-origin: 0%
		left: 17px
		background-color: #0E284A

// =============================
// SLIDEOUT MENU
// =============================
.slideout-menu
	position: fixed
	z-index: 0
	background-color: #fff
	height: 100vh
	width: 100%
	left: 0
	top: -100vh
	transition: 1s transform, 1s opacity, 1s top
	opacity: 0//closed state
	// transform: translateY(-100vh)//closed state

	display: table
	
	&.opened
		// transform: translateY(0px)//opened state
		top: 0
		opacity: 1//opened state
	
	.slideout-nav
		display: table-cell
		vertical-align: middle
		text-align: center
		li
			padding: 20px
		a.slideout-nav__a
			+font-mixin( $f1, 1.875rem, 700, normal, normal, none , #0E284A)



// =============================
// LOGO
// =============================

// =============================
// NAVIGATION
// =============================
.nav
	padding: 20px 0	
	.top-nav
		display: table
		table-layout: fixed
		
		li
			display: table-cell
			vertical-align: middle
			a,button
				display: inline-block
				padding: 20px 25px

		&--left
			float: left

		&--right
			float: right
			button
				text-transform: none
	
	//MEDIA QUERY
	+mq('md-end', max)
		.top-nav
			&--left
				float: none
				width: 100%
				li
					text-align: center
			&--right
				float: none
				width: 100%
				li
					text-align: center
					.divider
						margin-right: 0


