//==============================================
//General styles ===============================
//==============================================

*
	box-sizing: border-box

*::-webkit-input-placeholder/* Chrome, Safari, Opera */
	color: #212121
	opacity: 1

*:-moz-placeholder/* Firefox */
	color: #212121
	opacity: 1

*::-moz-placeholder/* Firefox */
	color: #212121
	opacity: 1

*:-ms-input-placeholder/* IE10–11 */
	color: #212121!important
	opacity: 1!important

::-ms-input-placeholder/* Edge */
	color: #212121

::placeholder/* CSS Working Draft */
	color: #212121

body
	font-family: $f1
	color: $font-color1
	overflow-x: hidden
	//padding-top: 80px //padding for fixed nav menu

body input:focus:required:invalid,
body textarea:focus:required:invalid
	color: #666
body input:required:valid,
body textarea:required:valid
	color: #666

main,aside
	border: none
	background: none	
	overflow: visible
	margin: 0
	padding: 0
	width: auto
	
header
	padding: 0
section
	padding: 0

ul
	list-style-type: none
	margin: 0
	padding: 0
	li
		padding: 0
		a

h1,h2,h3,h4,h5,h6, nav
	margin: 0
	padding: 0

p
	padding: 0
	margin: 0

a	
	color: $font-color1
	text-decoration: none
	&:hover
		text-decoration: none
		cursor: pointer
	&:focus
		text-decoration: none
		outline: none

input, textarea
	padding: 10px
	&:focus
		outline: none
textarea
	resize: none		

button
	border: none
	background-color: transparent
	&:focus
		outline: none
	&:hover
		cursor: pointer

img

	

